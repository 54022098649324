import { Input, Form, Button } from "antd";
import { useState } from "react";
import { loginRequest } from "../api/auth/passport";

export function LoginPage() {

    return (
        <div className="w-screen h-screen bg-white phone:bg-blue-ortega flex flex-wrap gap-28 justify-center items-center">
            <div className="hidden phone:block text-white m-auto w-[300px] text-center">
                We do not support mobile devices yet. Please use a desktop or laptop computer.
            </div>
            <div className="flex flex-row phone:hidden">
                <div className="h-screen w-[65vw] relative">
                    <img src="assets/main_image.webp"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-blue-ortega opacity-40 mix-blend-multiply"></div>
                </div>
                <section className="bg-white flex w-[35vw] border-l">
                    <LoginComponent />
                </section>
            </div>
        </div>
    );
}

function LoginComponent() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");


    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        console.log(`Submitting username ${username} and password ${password}`);
        // Add your login logic here
    }

    return (
        <div className="m-auto w-[375px]">
            <div className={"w-[100%] flex justify-between"}>
                <img src={"assets/logo.svg"} width="250" className="!mx-auto" alt="Company's logo" />
            </div>
            <LoginForm />
        </div>
    )
}

function LoginForm() {

    const onFinish = (values: any) => {
        console.log("Trying to login");
        loginRequest(values.email, values.password).then((response) => {
            if (response?.status === 200) {
                window.location.href = "/";
            }
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    type FieldType = {
        email?: string;
        password?: string;
        remember?: string;
    };


    return (
        <Form
            name="basic"
            layout="vertical"
            className="flex flex-col gap-0 p-6 ti"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <p className="text-black font-bold text-xl pb-6">Sign in to your account</p>
            <Form.Item<FieldType>
                name="email"
                rules={[{ required: true, type: "email", message: 'Please input your email!' }]}
            >
                <Input placeholder="Email"/>
            </Form.Item>
            <Form.Item<FieldType>
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password placeholder="Password"/>
            </Form.Item>
            <Form.Item className="mx-auto">
                <Button htmlType="submit" type="default">
                    Login
                </Button>
            </Form.Item>
            <p className="w-[250px] text-sm text-black m-auto text-center">
                Sign in using the account credentials provided by the software provider.
            </p>
        </Form>
    )
}