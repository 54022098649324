export const dictionary: { [key: string]: DictionaryProps } = {
    "addClient": {title: "Add New Client", link: "/clients/new", prev: "/clients"},
    "viewClient": {title: "", link: `/clients/view/`, prev: "/clients"},
    "viewAllClients": {title: "All Clients", link: "/clients"},
    "viewAllInvoices": {title: "All Invoices", link: "/invoices"},
    "viewAllQuotes": {title: "All Quotes", link: "/quotes"},
    "viewBalance": {title: "My Balance", link: "/balance"},
    "addInvoice": {title: "Add New Invoice", link: "/invoices/new/", prev: "/invoices"},
    "viewInvoice": {title: "", link: "/invoices/view/", prev: "/invoices"},
    "viewInventory": {title: "View Inventory", link: "/inventory"},
    "addNewInventory": {title: "Add New Inventory Item", link: "/inventory/new", prev: "/inventory"},
    "viewInventoryItemHistory": {title: "", link: "/inventory/history/", prev: "/inventory"},
};

interface DictionaryProps {
    title: string;
    link: string;
    prev? : string;
}

export function detirminePrviousPage(link: string): string {
    return Object.entries(dictionary)
        .filter(([key, value]) => value.link.includes(link))
        .map(([key, value]) => value.prev)[0] || "";
}

export function searchDictionary(query: string): DictionaryProps[] {
    return Object.entries(dictionary)
        .filter(([key, value]) => value.title.toLowerCase().includes(query.toLowerCase()))
        .map(([key, value]) => ({title: value.title, link: value.link}));
}

export function findTitleBsedOnLink(link: string): string {
    return Object.entries(dictionary)
        .filter(([key, value]) => value.link === link)
        .map(([key, value]) => value.title)[0];
}