import { ClientsTable } from './ClientsTable'
import { getAllClients, ClientProps } from '../../../api/clients/clients'
import { useEffect, useState } from 'react'
import { Button, Input } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { dictionary } from "../../actions/Dictionary"
import { addressToString, AddressProps} from '../../../api/addresses/addresses'
import { useLocation } from 'react-router-dom'

export function ClientsSubpage() {
    const navigate = useNavigate()

    const [allClients, setAllClients] = useState<ClientProps[]>([])
    const [clients, setClients] = useState<ClientProps[]>([])
    const [searchKey, setSearchKey] = useState("");
    const location = useLocation();

    
    async function getClients() {
        const data = await getAllClients()
        console.log(data)
        if (data) {
            data.sort((a, b) => { return a.firstName?.localeCompare(b.firstName || "") || a.lastName?.localeCompare(b.lastName || "") })
            setClients(data)
            setAllClients(data)
        }
    }

    // a function that finds elements in the clients array that match the search key, handling the search key being empty
    function findElements(key: string){
        //remove all whitespace from the key
        key = key.replace(/\s/g, '')
        if(key === ""){
            return allClients
        }
        else{
            return allClients.filter((client) => {
                let clientsAddresses = client.addresses?.map((address: AddressProps) => addressToString(address))

                return client.firstName?.toLowerCase().includes(key.toLowerCase()) || 
                client.lastName?.toLowerCase().includes(key.toLowerCase()) || 
                client.companyName?.toLowerCase().includes(key.toLowerCase()) || 
                client.email?.toLowerCase().includes(key.toLowerCase()) || 
                client.mobileNumber?.toLowerCase().includes(key.toLowerCase()) || 
                clientsAddresses?.some((address: string) => address.toLowerCase().includes(key.toLowerCase()))
            })
        }
    }

    useEffect(() => {
        getClients()
    }, [location.pathname])

    useEffect(() => {
        setClients(findElements(searchKey))
    },[searchKey] )

    return (
        <div className="w-full h-full flex flex-col gap-2">
            <div className='w-[400px] flex flex-row gap-2 px-4 pt-4'>
                <Input placeholder="Search..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                <Button type='primary' className='bg-blue-ortega' icon={<PlusCircleOutlined />} onClick={() => navigate(dictionary["addClient"].link)}>Add New Client</Button>
            </div>
            <ClientsTable clients={clients}/>
        </div>  
    )
}