import { useState } from 'react';
import { AdvancedMarker, Pin, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { MapAddressProps } from '../../../api/addresses/addresses'
import { Button } from 'antd';
import { dictionary } from '../../actions/Dictionary';
import { useNavigate } from 'react-router-dom';

interface MarkerProps extends MapAddressProps {
    customerId: number;
}

export function CustomMarker(props: MarkerProps) {

    const navigate = useNavigate();
    const link = dictionary["viewClient"].link + props.customerId

    const [color, setColor] = useState('#FBBC04')

    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infowindowShown, setInfowindowShown] = useState(false);

    const toggleInfoWindow = () => {
        setInfowindowShown(previousState => !previousState);
        // setColor('#FF0000')
    }

    const closeInfoWindow = () => {
        setInfowindowShown(false)
        // setColor('#FBBC04')
    };


    // function onHoverEnter() {
    //     setColor('#FF0000')
    //     console.log('hover')
    // }

    // function onHoverLeave() {
    //     setColor('#FBBC04')
    // }


    return (
        // <div onClick={this.props.onClick} className=" absolute -top-[10px] -left-[10px] z-10 rounded-full min-h-[20px] min-w-[100px] p-4 text-white text-xs bg-blue-ortega">

        <AdvancedMarker position={props.position} ref={markerRef} onClick={toggleInfoWindow} >
            <Pin background={color} glyphColor={'#000'} borderColor={'#000'} />
            {infowindowShown && (
                <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
                    <h1 className='text-sm  text-bold'>{props.customerName}</h1>
                    <p className='text-xs'>{props.address}</p>
                    <Button type='primary' onClick={() => navigate(link)} size='small' className='mt-2 text-xs'>View Client</Button>
                </InfoWindow>
            )}
        </AdvancedMarker>
    )

}