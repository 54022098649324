import { useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom'

export const PrivateRoutes = () => {
    const [isAuthenticated, setIsAuthenticated] = useState( localStorage.getItem('user') !== null );
        return (
        isAuthenticated ? <Outlet /> : <Navigate to="/login" />
    )
}

    