import React, { useEffect } from 'react';
import { Button, message, Space } from 'antd';

interface MessageProps {
    type: 'success' | 'error' | 'warning';
    text: string;
    trigger: boolean;
}

export function Message(props: MessageProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: props.text,
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: props.text
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: props.text
    });
  };

  useEffect(() => {
    if (props.trigger === true) {
      if (props.type === 'success') {
        success()
      } else if (props.type === 'error') {
        error()
      } else if (props.type === 'warning') {
        warning()
      }
    }

  }, [props.trigger]);

  return (
    <>
      {contextHolder}
    </>
  );
};