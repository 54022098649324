// InventoryTable.tsx

import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import {EditableContext, InventoryItemRecieveColumns, useEditableContext} from './EditableContext';
import EditableCell from './table_elements/EditableCell';
import EditableImageCell from './table_elements/EditableImageCell';
import DeleteInventoryButton from './table_elements/DeleteInventoryButton';
import EditInventoryButton from './table_elements/EditInventoryButton';
import type { ColumnsType } from 'antd/es/table';
import ViewHistoryInventoryButton from "./table_elements/ViewHistoryInventoryButton";
import RecoverInventoryButton from "./table_elements/RecoverInventoryButton";

function Actions({ record, trigger, setTrigger, reloadInventory }: { record: InventoryItemRecieveColumns, trigger: boolean,  setTrigger: (trigger: boolean) => void,     reloadInventory: (newItem: boolean) => void
}) {
    return (
        record.deleted ?
            (
                <div className={'mr-auto'}>
                    <RecoverInventoryButton record={record} trigger={trigger} setTrigger={setTrigger}/>
                </div>

            )
                :
            (
                <div className="flex flex-row gap-4">
                    <EditInventoryButton record={record} reloadInventory={reloadInventory}/>
                    <ViewHistoryInventoryButton record={record}/>
                    <DeleteInventoryButton record={record}  trigger={trigger} setTrigger={setTrigger}/>
                </div>
            )
    );
}

interface InventoryTableProps {
    trigger: boolean;
    setTrigger: (trigger: boolean) => void
    reloadInventory: (newItem: boolean) => void
}
export const InventoryTable: React.FC<InventoryTableProps> = (props) => {

    const { data, setData } = useEditableContext();


    const columns: ColumnsType<InventoryItemRecieveColumns> = [
        {
            title: 'Number',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <span>{record.id > 0 ? record.id : "New"}</span>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <EditableCell field="name" type="text" value={record.name} record={record} />
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => <EditableCell field="description" type="textarea" value={record.description} record={record} />
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => <EditableCell field="price" type="number" value={record.price} record={record} currency={true}/>
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => <EditableCell field="quantity" type="number" value={record.quantity} record={record} />
        },
        {
            title: 'Image',
            dataIndex: 'linkToPicture',
            key: 'linkToPicture',
            render: (text, record) => <EditableImageCell field="imageBlob" value={record.linkToPicture} record={record} />
        },
        {
            title: '',
            dataIndex: 'edit',
            key: 'edit',
            align: 'right',
            render: (_, record) => <Actions record={record} trigger={props.trigger} setTrigger={props.setTrigger} reloadInventory={props.reloadInventory}/>
        }
    ];

    return (
            <Table
                className={"inventory-table"}
                columns={columns}
                dataSource={data}
                rowKey={record => record.key}
                rowClassName={(record, index) => {
                    return record.deleted ? 'deleted-row' : '';
                }}
            />
    );
};

export default InventoryTable;
