import React, { useEffect, useState } from 'react';
import { Button, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ClientProps } from '../../../api/clients/clients';
import { AddressProps, addressToString } from '../../../api/addresses/addresses';
import { EditOutlined, FileOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { dictionary } from '../../actions/Dictionary';
import {InvoiceDataRecieved} from "../../../api/documents/invoices";


// companyName: string,
// email: string,
// firstName: string,
// id: number,
// lastName: string,
// middleName: string,
// mobileNumber: string,
// notes: string,

interface DataType {
  key: React.Key;
  invoiceID: number;
  customer: string;
  customerID: number;
  invoiceDate: string;
  dueDate: string;
  tags: string[];
  services: string[];
  total: number;
  balance: number;
  formOfPayment: string
}


const columns: ColumnsType<DataType> = [
  {
    title: 'Number',
    width: 100,
    dataIndex: 'invoiceID',
    key: '1',
    fixed: 'left',
    render: (text: string) => <p className='font-bold'>{text}</p>,
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    width: 100,
    key: '2',
    fixed: 'left',

    render: (text: string) => <p className='font-bold'>{text}</p>,

  },
  {
    title: 'Invoice Date',
    dataIndex: 'invoiceDate',
    key: '3',
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: '4',
    render: (email: string) =>  <a href={`mailto:${email}`} className='text-blue-ortega'>{email}</a>
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: '5',
    render: (phone: string) => <a href={`tel:${phone}`} className='text-blue-ortega'>{phone}</a>
  },
  {
    title: 'Paid',
    dataIndex: 'total',
    key: '5',
    render: (phone: string) => <a href={`tel:${phone}`} className='text-blue-ortega'>{phone}</a>
  },
  { title: 'Services', 
    dataIndex: 'services', 
    key: '6',
  },
  {
    title: "",    
    dataIndex: 'invoiceID', 
    key: '8',
    fixed: 'right',
    width: 150,
    render: (id) => <EditClientButton link={dictionary["viewInvoice"].link + id} />
  },
];


function EditClientButton(props: { link: string }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(props.link);
  };

  return (
    <Button type='primary' className='bg-blue-ortega' icon={<EditOutlined />} onClick={handleClick}>
      Edit Invoice
    </Button>
  );
}


interface InvoicesTableProps {
  invoices: InvoiceDataRecieved[];
}

export function InvoicesTable(props: InvoicesTableProps){
  const [fixedTop, setFixedTop] = useState(false);

  const [data, setData] = useState<DataType[]>([]);




  function updateInvoicesData() {
    
    let tempData: DataType[] = [];

    for (let i = 0; i < props.invoices.length; i++) {
      tempData.push({
        key: i,
        invoiceID: props.invoices[i].id,
        customer: props.invoices[i].customer.firstName + " " + props.invoices[i].customer.lastName,
        customerID: props.invoices[i].customerId,
        invoiceDate: new Date(props.invoices[i].invoiceDate).toLocaleDateString('en-US'),
        dueDate: new Date(props.invoices[i].dueDate).toLocaleDateString('en-US'),
        tags: props.invoices[i].tags.split("|||"),
        services: props.invoices[i].services.map((service) => service.name),
        total: props.invoices[i].total,
        balance: props.invoices[i].balance,
        formOfPayment: props.invoices[i].PaymentForm.name
      });
    }

    setData(tempData)

  }

  
  useEffect(() => {
    updateInvoicesData()
  }, [props.invoices]);
  


  return (
    <Table
      columns={columns}
      loading={false}
      dataSource={data}
      size='large'
      className='px-4'
      scroll={{ x: true}}
      summary={() => (
        <Table.Summary fixed={fixedTop ? 'top' : 'bottom'}>
         
        </Table.Summary>
      )}
    
    />
  );
};

