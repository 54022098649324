import { Button, Checkbox, DatePicker, Form, Input, InputNumber, SelectProps, Table } from 'antd';
import { customizeRequiredMark } from '../../components/RequiredMark';
import { Typography, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { addNewClient, ClientAddProps, ClientProps, getAllClients, updateClient } from '../../../api/clients/clients';
import { getClientById } from '../../../api/clients/clients';
import { InvoiceData, InvoiceService } from '../../../api/documents/invoices';
import { ServiceTable } from './ServicesTable';
import { createInvoice } from "../../../api/documents/invoices";

interface AddInvoiceModalProps {
    sendMessage: (messageType: 'success' | 'error' | 'warning', messageText: string) => void;
    resetMessage: () => void;
    setId: (id: number) => void;
    id: number;
}


export function AddInvoiceSection(props: AddInvoiceModalProps) {

    const { Title } = Typography;

    const [form] = Form.useForm();

    const client = {
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        companyName: '',
        email: '',
        phoneNumber: '',
        mobileNumber: '',
    };

    useEffect(() => {
        if (props.id !== -1) {

            form.setFieldsValue({
                title: 'Loading...',
                firstName: 'Loading...',
                middleName: 'Loading...',
                lastName: 'Loading...',
                suffix: 'Loading...',
                companyName: 'Loading...',
                email: 'Loading...',
                phoneNumber: 'Loading...',
                mobileNumber: 'Loading...',
            });

            getClientById(props.id).then((response) => {
                form.setFieldsValue({
                    title: response?.title,
                    firstName: response?.firstName || "ERROR",
                    middleName: response?.middleName,
                    lastName: response?.lastName || "ERROR",
                    suffix: response?.suffix,
                    companyName: response?.companyName,
                    email: response?.email,
                    phoneNumber: response?.phoneNumber,
                    mobileNumber: response?.mobileNumber,
                });
            })
        } else {
            form.setFieldsValue({
                title: '',
                firstName: '',
                middleName: '',
                lastName: '',
                suffix: '',
                companyName: '',
                email: '',
                phoneNumber: '',
                mobileNumber: '',
            });
        }
    }, [props.id]);

    const onFinish = async (values: any) => {
        if (props.id === -1) {
            // await createNewClient(values)

            let data: InvoiceData = values
            data.services = serviceTableData
            data.total = total
            data.balance = total
            data.tags = values.tags.join('|||');
            data.PaymentForm = {
                name: "none",
                amount: 0
            }

            if (data) {
                // @ts-ignore
                await createInvoice(data).then((response) => {
                    if (response) {
                        //props.setId(response.id)
                        props.sendMessage('success', 'Invoice created successfully')
                    } else {
                        props.sendMessage('error', 'Invoice creation failed')
                    }
                })
            }
        } else {
            //await updateClientDetails(values, props.id)
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    type FieldType = InvoiceData;



    // ######################### SELECT CLIENT #########################
    // 1. getClients ->  2. setClients -> 3. setOptions
    const [clients, setClients] = useState<ClientProps[]>([]);
    const [options, setOptions] = useState<SelectProps['options']>([]);

    useEffect(() => {
        getAllClients().then((response) => {
            if (response) {
                setClients(
                    response
                        .sort((a, b) => { return a.firstName?.localeCompare(b.firstName || "") || a.lastName?.localeCompare(b.lastName || "") })
                )
            }
        })
    }, []);

    useEffect(() => {
        let tempOptions: SelectProps['options'] = []
        for (let i = 0; i < clients.length; i++) {
            tempOptions.push({
                value: clients[i].id,
                label: clients[i].firstName + " " + clients[i].lastName,
            });
        }
        setOptions(tempOptions)
    }, [clients]);

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    // ######################### SELECT CLIENT #########################

    const [services, setServices] = useState<string[]>([]);
    const [serviceTableData, setServiceTableData] = useState<InvoiceService[]>([]);
    const [total, setTotal] = useState(0);

    function onServicesChange(value: string[]) {
        setServices(value);
    }

    function formatTotal(total: number) {
        return `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <>
            <Title level={2}>Invoice's Details</Title>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                requiredMark={customizeRequiredMark}
                form={form}
                initialValues={client}
            >
                <div className='flex flex-row gap-4'>
                    <Form.Item<FieldType>
                        name="customerId"
                        rules={[{ required: true, message: 'Please provide customer' }]}
                        className='grow'
                    >
                        <Select
                            className='min-w-[700px]'
                            options={(options || []).map((d) => ({
                                value: d.value?.toString() || "",
                                label: d.label?.toString() || "",
                            }))}
                            filterOption={filterOption}
                            showSearch
                            placeholder="Select Customer"

                        />
                    </Form.Item>
                </div>
                <div className='flex flex-row gap-4 w-full'>
                    <Form.Item<FieldType>
                        name="invoiceDate"
                    >
                        <DatePicker
                            placeholder='Invoice Date'
                            showToday
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        name="dueDate"
                    >
                        <DatePicker
                            placeholder='Due Date'
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="tags"
                        className='grow'
                    >
                        <Select
                            mode="tags"
                            className='min-w-0'
                            placeholder="Tags"
                        />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item<FieldType>
                        name="services"
                    >
                        <Select
                            mode="tags"
                            className='min-w-0'
                            placeholder="Services"
                            onChange={onServicesChange}
                        />
                    </Form.Item>
                </div>
                <div className='mb-4'>
                    <ServiceTable services={services} setTotal={setTotal} setServiceTableData={setServiceTableData} />
                </div>
                <div className='flex gap-4'>
                   
                    <Form.Item<FieldType>
                        name="total"
                        className='ml-auto mr-4'
                    >
                        <p className='text-2xl text-right'>
                        {formatTotal(total)}
                        </p>
                    </Form.Item>

                </div>
                <Button htmlType="submit" type='primary'> {props.id === -1 && <>Add New Invoice</>} {props.id !== -1 && <>Submit Edits</>} </Button>
            </Form>
        </>
    )
}