import {
    ControlPosition,
    MapControl
} from '@vis.gl/react-google-maps';
import { DatePicker, DatePickerProps } from 'antd';
import { useState } from 'react';
import { Input } from 'antd';

const { RangePicker } = DatePicker;
const { Search } = Input;


interface CustomMapControlProps {
    onFilter: (value: string) => void
}

export function CustomMapControl(props: CustomMapControlProps) {

    const [month, setMonth] = useState('')
    const [startRange, setStartRangeDate] = useState('')
    const [endRange, setEndRangeDate] = useState('')

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    return (
        <MapControl position={ControlPosition.TOP_RIGHT}>
            <div className='bg-white mt-[10px] mr-[10px] shadow p-4 min-w-[250px] rounded flex flex-col gap-4'>
                <h1 className='text-bold'>Filters</h1>
                <Search placeholder="Search by name" onChange={item => props.onFilter(item.target.value.replace(/\s/g, '')
                    || "")} enterButton />
                <DatePicker onChange={onChange} picker="month" />
                <RangePicker />
            </div>
        </MapControl>
    )
}