import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Alert, Space } from 'antd';



interface LoadingProps {
    size?: "small" | "default" | "large";
    state: "loading" | "error" | "success";
    errorMessage: string;
}
export function Loading(props: LoadingProps) {

    const spinnerSizeNumber = props.size === "small" ? 24 : props.size === "default" ? 32 : 40;

    const loading =  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} size={spinnerSizeNumber} spin />}></Spin>
    const error = <Alert message={props.errorMessage} type="error" />
    const success = <></>

    return (
        <div className="flex w-max ">
            {props.state === "loading" ? loading : props.state === "error" ? error : success}
        </div>
    );
}