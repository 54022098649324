// EditInventoryButton.tsx

import React from 'react';
import { Button, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useEditableContext, InventoryItemRecieveColumns } from '../EditableContext';
import {
    updateInventoryItem,
    addNewInventoryItem,
    uploadImageToImgBB,
    InventoryItemSend,
    InventoryItemRecieve
} from "../../../../api/inventory/inventory";

interface EditInventoryButtonProps {
    record: InventoryItemRecieveColumns;
}

interface EditInventoryButtonProps {
    record: InventoryItemRecieveColumns;
    reloadInventory: (newItem: boolean) => void;
}

const EditInventoryButton: React.FC<EditInventoryButtonProps> = (props: EditInventoryButtonProps) => {
    const { data, setData } = useEditableContext();

    const toggleEdit = async () => {
        if (!props.record.inEdit) {
            // Enable editing
            const newData = data.map(item => item.key === props.record.key ? { ...item, inEdit: true } : item);
            setData(newData);
        } else {
            // Submit changes
            props.record.id < 0 ? await createNewItem() : await updateExistingItem();
        }
    };

    const updateExistingItem = async () => {
        if (props.record.name === "" || props.record.price <= 0 || props.record.quantity <= 0) {
            message.warning("Please fill out all fields correctly");
            return;
        }

        let link = props.record.linkToPicture;
        if (props.record.imageBlob) {
            link = await uploadImageToImgBB(props.record.imageBlob);
        }

        const updatedItem: InventoryItemSend = {
            name: props.record.name,
            description: props.record.description,
            price: props.record.price,
            quantity: props.record.quantity,
            linkToPicture: link
        };

        updateInventoryItem(updatedItem, props.record.id).then(() => {
            const newData = data.map(item => item.key === props.record.key ? { ...item, inEdit: false } : item);
            setData(newData);
            message.success("Item updated");
        }).catch(() => {
            message.error("Error updating item");
        });
    };

    const createNewItem = async () => {
        if (props.record.name === "" || props.record.price < 0 || props.record.quantity < 0) {
            message.warning("Please fill out all fields correctly");
            return;
        }

        let link = "";
        if (props.record.imageBlob) {
            link = await uploadImageToImgBB(props.record.imageBlob);
        }

        const newItem: InventoryItemSend = {
            ...props.record,
            linkToPicture: link
        };

        addNewInventoryItem(newItem).then(response => {
            //const newData = data.map(item => item.key === props.record.key ? { ...item, id: response.data.id, inEdit: false } : item);
            //setData(newData);
            props.reloadInventory(false);
            message.success("New item added");
        }).catch(() => {
            message.error("Error adding new item");
        });
    };

    return (
        <Button onClick={toggleEdit} icon={<EditOutlined />}>
            {props.record.inEdit ? "Save" : "Edit Item"}
        </Button>
    );
};

export default EditInventoryButton;
