import React, { useState } from 'react';
import { Button, Drawer, Select, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getExcelReport } from '../../../api/inventory/inventory';
import { message } from "antd";

interface InventoryReportGeneratorProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const periodOptions = [
    { value: 'year', label: 'Year' },
    { value: 'quarter', label: 'Quarter' },
    { value: 'month', label: 'Month' },
    { value: 'biweek', label: 'Biweek' },
    { value: 'week', label: 'Week' },
];

const yearFormat = 'YYYY';

export function InventoryReportGenerator({ open, setOpen }: InventoryReportGeneratorProps) {
    const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[0].value);
    const [selectedYear, setSelectedYear] = useState(dayjs().format(yearFormat));

    const handleYearChange = (date: dayjs.Dayjs | null) => {
        setSelectedYear(date ? date.year().toString() : dayjs().format(yearFormat));
    };

    const downloadReport = async () => {
        try {
            await getExcelReport({ year: selectedYear, period: selectedPeriod });
            message.success('Report downloaded successfully');
        } catch (error) {
            message.error('Failed to download report');
        }
    };

    return (
        <Drawer title="Report Generator" onClose={() => setOpen(false)} open={open}>
            <div className="flex flex-col gap-4">
                <Select
                    value={selectedPeriod}
                    onChange={setSelectedPeriod}
                    options={periodOptions}
                />
                <DatePicker
                    picker="year"
                    defaultValue={dayjs(selectedYear, yearFormat)}
                    format={yearFormat}
                    onChange={handleYearChange}
                />
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={downloadReport}
                >
                    Generate & Download
                </Button>
            </div>
        </Drawer>
    );
}
