import { getAllClients } from '../clients/clients';
import { axios } from '../axios';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;


export interface AddressProps {
    streetAddress1: string,
    streetAddress2: string,
    city: string,
    state: string,
    country: string,
    zip: string,
    id: number,
    latitude: number,
    longitude: number,
    customerId: number
}

export interface MapAddressProps {
    id: number,
    customerId: number,
    customerName: string,
    address: string
    position: {
        lat: number,
        lng: number
    }
}

export function addressToString(Address: AddressProps): string {
    let fullAddress = `${Address.streetAddress1}`;
    if (Address.streetAddress2) {
        fullAddress += `, ${Address.streetAddress2}`;
    }
    return `${fullAddress}, ${Address.city}, ${Address.state}, ${Address.zip}`;
}

// export const geocodeAddress = async (address: string) => {
//     // Use Google Geocoding API to convert the address to coordinates
//     // This is a simplified example. You should use the actual API.
//     const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
//     const data = await response.json();
//     if (data.results[0] === undefined) {
//         return {0 : 0}
//     }
//     if (data.results && data.results.length > 0) {
//       const { lat, lng } = data.results[0].geometry.location;
//       return { lat, lng };
//     }
//     return {0 : 0}
// };

export const getAddresses = async (): Promise<MapAddressProps[]> => {
    // Use Google Geocoding API to convert the address to coordinates
    // This is a simplified example. You should use the actual API.
    console.log("starting...");

    const clients = await getAllClients();
    const addresses: MapAddressProps[] = [];

    if (clients)
    for (const client of clients) {
        if (client.addresses)
        for (const address of client.addresses) {
            let addressString = addressToString(address)
            // const { lat, lng } = await geocodeAddress(addressString);
            if (address.longitude === 0 && address.latitude === 0) continue;
                console.log({
                    id: address.id,
                    customerId: address.customerId,
                    customerName: `${client.firstName} ${client.lastName}`,
                    address: addressString,
                    position: {
                        lat: address.latitude,
                        lng: address.longitude
                    }
                });
                addresses.push({
                    id: address.id,
                    customerId: address.customerId,
                    customerName: `${client.firstName} ${client.lastName}`,
                    address: addressString,
                    position: {
                        lat: address.latitude,
                        lng: address.longitude
                    }
                });
            }
    }
    return addresses;
}

export const getAddressByCustomerID = async (id: number) => {
    try {
        const response = await axios.get<AddressProps[]>(`/api/addresses/customer/${id}`);
        return response;
    } catch(error) {
        console.error(error);
    }
}

export const addAddress = async (address: AddressProps, clientID: number) => {
    address.customerId = clientID;
    try {
        const response = await axios.post<AddressProps>('/api/addresses/add', {address: address})
        return response;
    } catch(error: any) {
        console.error(error);
    }
}

export const updateAddress = async (address: AddressProps, id: number) => {
    address.id = id;
    try {
        const response = await axios.post<AddressProps>(`/api/addresses/update`, {address: address});
        return response;
    } catch(error) {
        console.error(error);
    }
}