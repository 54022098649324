export function WelcomeSubpage() {

    function greeting() {
        let greeting = '';
        // Get the current hour from the computer's local time
        const currentHour = new Date().getHours();
        // Determine the time of day based on the current hour
        if (currentHour >= 5 && currentHour < 12) {
            greeting = 'Good morning,';
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = 'Good afternoon,';
        } else {
            greeting = 'Good evening,';
        }
        return greeting
    }

    return (
        <div className="w-full h-full flex">
            <div className="m-auto flex flex-row gap-24 pb-[60px]">
                <div className="flex flex-col my-auto gap-2">
                    <h1 className="text-left text-5xl font-bold text-black">{greeting()}</h1>
                    <h2 className="text-left text-5xl font-normal text-red-ortega underline underline-offset-8">
                        Fatima
                    </h2>
                </div>
                <img src={"assets/credit-card.svg"} className="w-[400px]" alt="Credit Card" />
            </div>
        </div>
    )
}