import React, { useEffect, useState } from 'react';
import { InboxOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import {Upload, message, Table, Button, Popconfirm, Typography} from 'antd';
import {getClientById, uploadFile, deleteFile, downloadFile} from '../../../api/clients/clients';
import { FilesProps } from '../../../api/clients/clients';

const { Dragger } = Upload;

interface SimpleCustomRequestOptions {
    file: File;
    onSuccess: (response: any, file: File) => void;
    onError: (error: Error) => void;
}

interface AddFilesProps {
    clientId: number;
}

export const AddFiles: React.FC<AddFilesProps> = (props: AddFilesProps) => {
    const clientId = props.clientId; // This should be dynamically set based on your application's context

    const [files, setFiles] = useState<FilesProps[]>([]);

    useEffect(() => {
        getClientById(clientId).then((response) => {
            if (response) {
                setFiles(response.files);
            }
        });
    }, [clientId]);

    const customRequest = async (options: any) => {
        const { onSuccess, onError, file } = options;
        try {
            const response = await uploadFile(file, clientId);
            setFiles([...files, response.data]);
            onSuccess(response.data, file);
            message.success(`${file.name} file uploaded successfully.`);
        } catch (error: any) {
            console.error('Upload error:', error);
            onError(error);
            message.error(`${file.name} file upload failed.`);
        }
    };

    const handleDownload = (file: FilesProps) => {
        // Implement file download logic
        downloadFile(clientId, file.id, file.name)
    };

    const handleDelete = async (file: FilesProps) => {
        deleteFile(clientId, file.id).then((response) => {
            if (response) {
                setFiles(files.filter((f) => f.id !== file.id))
                message.success(`${file.name} file deleted successfully.`);
            } else {
                message.error(`${file.name} file deletion failed.`);
            }
        });
    };

    const columns = [
        {
            title: 'File Name',
            dataIndex: 'name',
            key: 'fileName',
        },
        {
            title: 'Date Created',
            dataIndex: 'createdAt',
            key: 'dateCreated',
            sorter: (a: FilesProps, b: FilesProps) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
            render: (text: any, record: FilesProps) => new Date(record.createdAt).toLocaleDateString(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: FilesProps) => (
                <>
                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(record)}>
                        Download
                    </Button>
                    <Popconfirm
                        title="Are you sure you want to delete this file?"
                        onConfirm={() => handleDelete(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger icon={<DeleteOutlined />} style={{ marginLeft: '8px' }}>
                            Delete
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return (
        <div className="flex flex-col gap-2 mt-">
            <Typography.Title level={2}>Files</Typography.Title>
            <Table dataSource={files} columns={columns}/>
            <Dragger
                showUploadList={false}
                name="file"
                multiple={true}
                customRequest={customRequest}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                </p>
            </Dragger>
        </div>
    );
};
