import React, { useState } from 'react';
import { FileTextOutlined, ShopOutlined, FileProtectOutlined, TeamOutlined, CreditCardOutlined, GlobalOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useNavigate } from "react-router-dom";



const items: MenuProps['items'] = [
  {
    label: 'Balance',
    key: 'balance',
    icon: <CreditCardOutlined />,
  },
  {
    label: 'Inventory',
    key: 'inventory',
    icon: <ShopOutlined />,
  },
  {
    label: 'Quotes',
    key: 'quotes',
    icon: <FileTextOutlined />,
  },
  {
    label: 'Invoices',
    key: 'invoices',
    icon: <FileProtectOutlined />,
  },
  {
    label: 'Clients',
    key: 'clients',
    icon: <TeamOutlined />,
  },
  {
    label: 'Map',
    key: 'map',
    icon: <GlobalOutlined />,
  },
];

export function MainMenu() {
  const [current, setCurrent] = useState('');
  const navigate = useNavigate();


  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    navigate(e.key);
  };

  return <div className="flex-col grow items-center justify-center p-0 gap-0 bg-white">
    <Menu style={{ border: "none" }} className='h-full' onClick={onClick} selectedKeys={[current]} mode="vertical" items={items} /></div>;
};

