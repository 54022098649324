import React, { useEffect, useState } from 'react';
import { Table, InputNumber } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Checkbox } from 'antd';
import {InvoiceService} from "../../../api/documents/invoices";



interface Service {
  key: string;
  name: string;
  rate: number;
  quantity: number;
  amount: number;
  saveAsTemplate: boolean;
}

interface ServiceTableProps {
    setTotal: (total: number) => void;
    setServiceTableData: (data: InvoiceService[]) => void;
    services: string[];
}

export function ServiceTable(props: ServiceTableProps) {
  const [dataSource, setDataSource] = useState<Service[]>([]);

  useEffect(() => {
    let newData = props.services.map((service, index) => {
      // Find if the current service already exists in the dataSource
      const existingService = dataSource.find(item => item.name === service);
      // If it exists, return the existing data
      if (existingService) {
        return existingService;
      }
      // If it's a new service, add it with initial values
      return {
        key: index.toString(),
        name: service,
        rate: 0,
        quantity: 1,
        amount: 0,
        saveAsTemplate: false,
      };
    });

    setDataSource(newData);
    }, [props.services]);

  const handlePriceChange = (value: number | undefined, key: string) => {
    const newData = dataSource.map(item => item.key === key ? {
      ...item,
      rate: value ?? 0,
      amount: (value ?? 0) * item.quantity
    } : item);

    setDataSource(newData);
    const preparedData = newData.map((item) => {
        return {
            name: item.name,
            rate: item.rate,
            quantity: item.quantity,
            amount: item.amount,
            saveAsTemplate: item.saveAsTemplate,
            tax: false
        }
    })
    props.setServiceTableData(preparedData);
  };

  const handleCheckboxChange = (value: boolean, key: string) => {
    const newData = dataSource.map((item) => {
      if (item.key === key) {
        return { ...item, saveAsTemplate: value };
      }
      return item;
    });
    setDataSource(newData);
  }

  const handleQuantityChange = (value: number, key: string) => {
    const newData = dataSource.map((item) => {
      if (item.key === key) {
        return { ...item, quantity: value, total: value * item.rate };
      }
      return item;
    });
    setDataSource(newData);
  };

  function formatTotal(total: number) {
    return `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
  const columns: ColumnsType<Service> = [
    { title: 'Service',
      dataIndex: 'name',
      key: 'name'
    },
    { 
      title: 'Price', 
      dataIndex: 'rate',
      key: 'rate',
      render: (text: number, record: Service) => (
        <InputNumber 
          defaultValue={text} 
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          onChange={(value) => handlePriceChange(value as number, record.key)} 
        />
      ),
      align: 'center',
    },
    { 
      title: 'Quantity', 
      dataIndex: 'quantity', 
      key: 'quantity', 
      render: (text: number, record: Service) => (
        <InputNumber 
          defaultValue={text} 
          onChange={(value) => handleQuantityChange(value as number, record.key)} 
        />
      ),
      align: 'center',
    },
    { title: 'Total', dataIndex: 'amount', key: 'amount',
      render: (text: number) => formatTotal(text),
      align: 'right',
      width: '40%',
    },
    {
      title: 'Save as Template',
      dataIndex: 'saveAsTemplate',
      key: 'saveAsTemplate',
      align: 'right',
      render: (checked: boolean, record: Service) => <Checkbox
          checked={checked}
          onChange={(e) => handleCheckboxChange(e.target.checked, record.key)}
      />,
    },
  ];


  useEffect(() => {
    const newSum = dataSource.reduce((sum, { amount }) => sum + amount, 0);
    props.setTotal(newSum);
  }, [dataSource, props.setTotal]);

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey="key"
      pagination={false}
    />
  );
}
