import { axios, downloadBlobAsFile } from '../axios';
import {AxiosResponse} from "axios";

export interface InventoryItemSend {
    name: string;
    description: string;
    quantity: number;
    price: number;
    linkToPicture: string;
}

export interface InventoryItemRecieve {
    id: number;
    name: string;
    description: string;
    quantity: number;
    price: number;
    linkToPicture: string
    deleted: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface InventoryItemReceiveWithHistory extends InventoryItemRecieve {
    changes: InventoryItemHistory[];
}

    export interface InventoryItemHistory {
    id: number;
    inventoryItemId: number;
    fieldName: string;
    oldValue: string;
    newValue: string;
    createdAt: string;
}

export async function getInventoryItems(): Promise<InventoryItemRecieve[]> {
    const res: any =  await axios.get('/api/inventory/all');
    return res.data;
}

export function getInventoryItem(id: number): Promise<AxiosResponse<InventoryItemReceiveWithHistory>>{
    return axios.get(`/api/inventory/${id}`);
}

export function addNewInventoryItem(inventoryItem: InventoryItemSend): Promise<AxiosResponse<InventoryItemRecieve>> {
    if (inventoryItem.linkToPicture === undefined) {
        inventoryItem.linkToPicture = "";
    }
    return axios.post('/api/inventory/new', inventoryItem);
}

export function updateInventoryItem(inventoryItem: InventoryItemSend, id: number): Promise<AxiosResponse<InventoryItemRecieve>> {
    return axios.post(`/api/inventory/${id}/update`, inventoryItem);
}

export function deleteInventoryItem(id: number): Promise<AxiosResponse<InventoryItemRecieve>> {
    return axios.delete(`/api/inventory/${id}`);
}

export function recoverInventoryItem(id: number): Promise<AxiosResponse<InventoryItemRecieve>> {
    return axios.post(`/api/inventory/${id}/recover`);
}


export async function uploadImageToImgBB(blob: Blob): Promise<string> {
    try {
        const formData = new FormData();
        formData.append('image', blob);
        const key = process.env.REACT_APP_IMAGEBB_API_KEY! // Replace with your ImgBB API key

        const response = await axios.post(`https://api.imgbb.com/1/upload?key=${key}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: false, // Disable credentials
        });
        return response.data.data.url;
    } catch (error) {
        throw error;
    }
}

interface InventoryReportParams {
    year: string;
    period: string;
}
export async function getExcelReport(props: InventoryReportParams): Promise<void> {
    try {
        const response: AxiosResponse<Blob> = await axios.get(`/api/inventory/all/report`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            params: {
                year: props.year, // Include the 'year' parameter
                period: props.period, // Include the 'period' parameter
            },
        });

        // Extract the filename from the response headers
        const contentDispositionHeader = response.headers['content-disposition'];
        const fileNameMatch = contentDispositionHeader && contentDispositionHeader.match(/filename="(.*?)"/);
        const fileName = fileNameMatch && fileNameMatch[1] ? fileNameMatch[1] : 'report.xlsx';

        // Create a blob URL for the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blobUrl = window.URL.createObjectURL(blob);

        // Create an invisible <a> element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName; // Use the extracted file name

        // Trigger the download
        downloadLink.click();

        // Clean up the blob URL
        window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
        throw error;
    }
}