// EditableCell.tsx

import React, { useState } from 'react';
import { Input } from 'antd';
import { useEditableContext } from '../EditableContext';
import { InventoryItemRecieveColumns } from '../EditableContext'; // Assuming it's in the same file

interface EditableCellProps {
    field: string;
    type: string;
    value: string | number;
    currency?: boolean;
    record: InventoryItemRecieveColumns;
}

const EditableCell: React.FC<EditableCellProps> = ({ field, type, value, record , currency}) => {
    const { data, setData } = useEditableContext();
    const [editValue, setEditValue] = useState(value);

    const handleBlur = () => {
        const newData = data.map((item) =>
            item.key === record.key ? {...item, [field]: editValue} : item
        );
        setData(newData);
    };

    function formatCurrency(value: string | number) {
        if (currency === undefined || !currency) return String(value);
        return `$ ${String(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }



    const inputElement = type === "textarea" ? (
        <textarea
            value={String(editValue)}
            onChange={(e) => setEditValue(e.target.value)}
            onBlur={handleBlur}
            className=' rounded-md p-2 border border-[#d9d9d9] focus:border-[#3A68BB] focus:outline-none focus:ring-[#3A68BB] focus:ring-offset-1 ring-opacity-50 mb-auto'
        />
    ) : (
        <Input
            type={type}
            value={String(editValue)}
            onChange={(e) => setEditValue(e.target.value)}
            onBlur={handleBlur}
            className='mb-auto'
        />
    );

    return record.inEdit ? inputElement : <span>{formatCurrency(value)}</span>;
};

export default EditableCell;
