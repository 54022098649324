import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalBase } from "../../components/Modal";
import { dictionary } from '../../actions/Dictionary';
import { getInventoryItem, InventoryItemHistory, InventoryItemRecieve } from "../../../api/inventory/inventory";
import {Table, TableColumnProps, Typography, Alert, Spin} from "antd";
import {Loading} from "../../components/Loading";
import { nanoid } from 'nanoid';



const { Text } = Typography;

interface AddClientModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface HistoryRow {
    id: number;
    name: string;
    description: string;
    price: number;
    quantity: number;
    linkToPicture: string;
    deleted: boolean;
    changedAt: string;
    changedFields: string[];
}

const renderHighlighted = (text: string, record: HistoryRow, field: string) => {
    const isHighlighted = record.changedFields.includes(field);
    const notDeleted = !record.deleted;
    return  notDeleted ? (isHighlighted ? <Text mark>{text}</Text> : text) : (<Text delete>{text}</Text>);
};

const renderDeleted = (text: string, record: HistoryRow) => {
    const recovered = record.changedFields.includes("deleted") && !record.deleted;
    const isDeleted = record.changedFields.includes("deleted") && record.deleted;
    return isDeleted ? (<Alert className={'text-center'} message="Deleted" type="error" />) : (recovered ?  <Alert message="Recovered" type="success" /> : "");
}


function readableTime(time: string) {
    const date = new Date(time);
    return date.toLocaleString();
}

export function InventoryHistoryModal({ open, setOpen }: AddClientModalProps) {
    const [id, setId] = useState(-1);
    const location = useLocation();
    const [history, setHistory] = useState<HistoryRow[]>([]);
    const [loadingState, setLoadingState] = useState<"loading" | "success" | "error">("loading");

    const changesColumns: TableColumnProps<HistoryRow>[] = useMemo(() => [
        {
            title: 'Changed At',
            dataIndex: 'changedAt',
            key: 'changedAt',
            render: (text: string) => readableTime(text),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: HistoryRow) => renderHighlighted(text, record, 'name'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string, record: HistoryRow) => renderHighlighted(text, record, 'description'),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text: string, record: HistoryRow) => renderHighlighted(text, record, 'price'),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text: string, record: HistoryRow) => renderHighlighted(text, record, 'quantity'),
        },
        {
            title: 'Image URL',
            dataIndex: 'linkToPicture',
            key: 'linkToPicture',
            render: (text: string, record: HistoryRow) => renderHighlighted(text, record, 'linkToPicture'),
        },
        {
            title: "Deleted",
            dataIndex: 'deleted',
            key: 'deleted',
            render: (text: string, record: HistoryRow) => renderDeleted(text, record),
        }
        // Uncomment or add additional columns as needed
        // {
        //     title: 'Changed Fields',
        //     dataIndex: 'changedFields',
        //     key: 'changedFields',
        //     render: (text, record) => renderHighlighted(text.join(', '), record, 'changedFields'),
        // }
    ], []);


    useEffect(() => {
        // ... same as before
    }, [location]);


    const getItemHistoryDetails = useCallback(async (id: number) => {
        try {
            const item = await getInventoryItem(id);
            createHistoryRows(item.data, item.data.changes);
            setLoadingState("success");
        } catch (error) {
            setLoadingState("error");
        }
    }, []);

    function recreateOriginalItem(item: InventoryItemRecieve, changes: InventoryItemHistory[]) {
        let temp: InventoryItemRecieve = { ...item }
        changes.forEach((change) => {
            if (change.fieldName === "name") {
                temp.name = change.oldValue;
            }
            if (change.fieldName === "description") {
                temp.description = change.oldValue;
            }
            if (change.fieldName === "price") {
                temp.price = parseFloat(change.oldValue);
            }
            if (change.fieldName === "quantity") {
                temp.quantity = parseFloat(change.oldValue);
            }
            if (change.fieldName === "linkToPicture") {
                temp.linkToPicture = change.oldValue;
            }
            if (change.fieldName === "deleted") {
                temp.deleted = change.oldValue === "true";
            }
        });
        return temp;
    }

    function makeAChange(item: InventoryItemRecieve, change: InventoryItemHistory) {
        let temp = {... item}
        if (change.fieldName === "name") {
            temp.name = change.newValue;
        }
        if (change.fieldName === "description") {
            temp.description = change.newValue;
        }
        if (change.fieldName === "price") {
            temp.price = parseFloat(change.newValue);
        }
        if (change.fieldName === "quantity") {
            temp.quantity = parseFloat(change.newValue);
        }
        if (change.fieldName === "linkToPicture") {
            temp.linkToPicture = change.newValue;
        }
        if (change.fieldName === "deleted") {
            temp.deleted = change.newValue === "true";
        }
        return temp;
    }

    function createHistoryRows(item: InventoryItemRecieve, changes: InventoryItemHistory[]) {

        const lastToFirstChanges = changes.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
        const original = recreateOriginalItem(item, lastToFirstChanges);

        const historyRows: HistoryRow[] = [];
        //push the last version
        // historyRows.push({ ...item, name: "LAST VERSION", changedAt: item.updatedAt, changedFields: [] });


        const firstToLastChanges = changes.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1);
        let tracker = { ...original};
        const changesRows: HistoryRow[] = [];
        let currentTime = original.createdAt
        let changedFields: string[] = [];
        firstToLastChanges.forEach((change) => {
            if (tracker.createdAt !== change.createdAt) {
                changesRows.push({ ...tracker, changedAt: tracker.createdAt, changedFields: changedFields })
                changedFields = [];
            }
            tracker = makeAChange(tracker, change)
            tracker.createdAt = change.createdAt;
            changedFields.push(change.fieldName)
        });

        //add the last change
        changesRows.push({ ...tracker, changedAt: tracker.createdAt, changedFields: changedFields })

        //push the changes in reverse order
        changesRows.reverse();
        changesRows.forEach((change) => {
            historyRows.push(change);
        })

        // //push the original version to debug
        // historyRows.push({ ...original, changedAt: item.createdAt, changedFields: [] });

        setHistory(historyRows);
    }


    useEffect(() => {
        // Extracting the ID from the URL
        const pathId = parseInt(location.pathname.split("/")[3]);

        // Check if the current path matches the expected route and if pathId is a valid number
        if (location.pathname.includes(dictionary["viewInventoryItemHistory"].link) && !isNaN(pathId)) {
            setId(pathId);
            getItemHistoryDetails(pathId);
        } else {
            // Reset the state if the path does not match or pathId is not valid
            setId(-1);
            setHistory([]);
        }
    }, [location, getItemHistoryDetails]); // Dependencies include location and the getItemHistoryDetails function


    return (
        <ModalBase open={open} setOpen={setOpen} title='' large>
                <div className='gap-4 py-4 flex flex-col'>
                    <section className='flex flex-col gap-2 mx-auto grow'>
                        <div className={"flex flex-row gap-4 items-center"}>
                            <h1 className={"text-2xl"}>Item's History</h1>
                            <Loading state={loadingState} errorMessage={"Error while fetching the data"}></Loading>
                        </div>
                        <Table columns={changesColumns} dataSource={history} rowKey={record => nanoid()} />
                    </section>
                </div>
        </ModalBase>
    );
}

