import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { PrivateRoutes } from './utils/PrivateRoutes';
import { LoginPage } from './pages/LoginPage';
import { DashboardPage } from './pages/DashboardPage';
import { WelcomeSubpage } from './pages/subpages/welcome/WelcomeSubpage';
import { ClientsSubpage } from './pages/subpages/clients/ClientsSubpage';
import { GoogleMapSubpage }  from './pages/subpages/map/GoogleMapSubpage';
import { InvoicesSubpage } from './pages/subpages/invoices/InvoicesSubpage';
import { QuotesSubpage } from './pages/subpages/quotes/QuotesSubpage';
import {InventorySubpage} from "./pages/subpages/inventory/InventorySubpage";


function App() {

  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes/>}>
          <Route 
            element={<DashboardPage/>}
            path='/'
            children={
                <>
                    <Route element={<WelcomeSubpage/>} path='/'/>
                    <Route element={<div>Balance</div>} path='/balance/*' />
                    <Route element={<InventorySubpage/>} path='/inventory/*' />
                    <Route element={<QuotesSubpage/>} path='/quotes/*' />
                    <Route element={<InvoicesSubpage/>} path='/invoices/*' />
                    <Route element={<ClientsSubpage/>} path='/clients/*' />
                    <Route element={<GoogleMapSubpage center={{
                        lat: 41.769865,
                        lng: -87.7273005
                }} zoom={11} />} path='/map/*' />
              </>
            }
          />
        </Route>
        <Route element={<LoginPage/>}  path='/login'/>
      </Routes>
    </Router>
  );
}


export default App;
