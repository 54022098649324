import { axios } from '../axios';

export const loginRequest = async (username: string, password: string) => {
    try {
        const response = await axios.post('/auth/login', { username, password }, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true});
        localStorage.setItem('user', JSON.stringify(response.data.user));
        return response
    } catch(error) {
        console.error(error);
    }
}

export const logout = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
}