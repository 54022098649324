import { Button, Collapse, CollapseProps, Form, Checkbox, Input, Col } from 'antd';

import { AddressProps, addressToString } from '../../../api/addresses/addresses';
import { EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { addAddress, updateAddress } from '../../../api/addresses/addresses';

import { getAddressByCustomerID } from '../../../api/addresses/addresses';

interface AddAddressModalProps {
    id: number;
    sendMessage: (messageType: 'success' | 'error' | 'warning', messageText: string) => void;
    resetMessage: () => void;
}

interface UseStateProps{
    key: string;
    label: string;
    children: JSX.Element;
}

export function AddAddressSection(props: AddAddressModalProps) {

    const [refresh, setRefresh] = useState(false)
    function update(){
        setRefresh(!refresh)
    }

    const [addresses, setAddresses] = useState<AddressProps[]>([]);
    useEffect(() => {
        getAddressByCustomerID(props.id).then((response) => {
            if (response?.status === 200) {
                setAddresses(response.data)
            } else {
                props.sendMessage('error', 'Could not load addresses')
            }
        })
    }, [props.id, refresh])
   

    const [items, setItems] = useState<UseStateProps[]>([]);
    useEffect(() => {

        let newItems: UseStateProps[] = []
        addresses.forEach((address: AddressProps) => {
            newItems.push({
                key: address.id.toString(),
                label: addressToString(address),
                children: EditAddress({ addressID: address.id, address: address, id: address.id, sendMessage: props.sendMessage, resetMessage: props.resetMessage, update: update}),
            })
        })
        setItems(newItems)
  
    }, [addresses])


    function addEmptyAddress() {

        let newItems: UseStateProps[] = [...items]
        let newAddress = {
            streetAddress1: "",
            streetAddress2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            id: -1,
            latitude: 0,
            longitude: 0,
            customerId: 0
        }

        newItems.push({
            key: (items.length + 1).toString(),
            label: "New Address",
            children: EditAddress({addressID: -1,  address: newAddress, id: props.id, sendMessage: props.sendMessage, resetMessage: props.resetMessage, update: update}),
        })

        setItems(newItems)
    }


    return (
        <div className='gap-4'>
            {items.length == 0 &&
                <>
                    <div className='flex items-center flex-col gap-2 py-8'>
                        <FileSearchOutlined style={{ fontSize: '36px' }} />
                        <p className='text-xs'>No addresses found</p>
                        {/* <span className='text-red-ortega text-xs'>debugging id: {props.id}</span> */}
                    </div>
                    <Button type='primary' className='bg-blue-ortega' onClick={addEmptyAddress} >Add New Address</Button>
                </>

            }
            {items.length > 0 &&
                <div className='py-8 flex flex-col gap-4'>
                    <Collapse className='w-full' items={items} defaultActiveKey={['1']} />
                    <Button type='default' className='w-fit' onClick={addEmptyAddress}>Add New Address</Button>
                </div>
            }
        </div>
    )
}

interface EditAddressProps{
    address: AddressProps;
    id: number;
    sendMessage: (messageType: 'success' | 'error' | 'warning', messageText: string) => void
    resetMessage: () => void
    update: () => void
    addressID: number
}

function EditAddress(props: EditAddressProps) {
    
    return (
        <section className='max-w-[800px]'>
            <AddressFields addressID={props.addressID} update={props.update} address={props.address} buttonText='Submit' sendMessage={props.sendMessage} resetMessage={props.resetMessage} id={props.id} />
        </section>
    )
}

interface AddressFieldsProps {
    address: AddressProps
    buttonText: string
    sendMessage: (messageType: 'success' | 'error' | 'warning', messageText: string) => void
    resetMessage: () => void
    id: number
    addressID: number
    update: () => void
}

function AddressFields(props: AddressFieldsProps) {

    type FieldType = AddressProps;


    const onFinish = async (values: AddressProps) => {
        if (props.addressID === -1) {
            await createNewAddress(values, props.id)
        } else {   
            await updateClientDetails(values, props.addressID)
        }
    };


    async function createNewAddress(values: AddressProps, id: number) {
        await addAddress(values, id).then((response) => {
            if (response?.status === 200) {
                props.sendMessage('success', 'Address added successfully')
                console.log(response.data)
                // props.setId(response.data.id || -1)
                props.update()
            } else {    
                props.sendMessage('error', 'Address could not be added')
            }
        }).then(() => {
            setTimeout(() => {
                props.resetMessage()
            }, 3000);
        })
    }

    async function updateClientDetails(values: AddressProps, id: number) {
        await updateAddress(values, id).then((response) => {
            if (response?.status === 200) {
                props.sendMessage('success', 'Changes are saved')
                console.log(response.data)
                // props.setId(response.data.id || -1)
                props.update()
            } else {    
                props.sendMessage('error', 'Address could not be modified')

            }
        }).then(() => {
            setTimeout(() => {
                props.resetMessage()
            }, 3000);
        })
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                className='max-w-[660px] mx-auto'
            >
                <div className='flex flex-col gap-0'>
                    <Form.Item<FieldType>
                        name="streetAddress1"
                        className='grow'
                        initialValue={props.address.streetAddress1 || ''}
                    >
                        <Input placeholder='Street Address 1' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="streetAddress2"
                        className='grow'
                        initialValue={props.address.streetAddress2 || ''}
                    >
                        <Input placeholder='Street Address 2' />
                    </Form.Item>
                </div>
                <div className='flex flex-row gap-4'>
                    <Form.Item<FieldType>
                        name="city"
                        className='grow'
                        initialValue={props.address.city || ''}
                    >
                        <Input placeholder='City' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="state"
                        className='grow'
                        initialValue={props.address.state || 'IL'}
                    >
                        <Input placeholder='State' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="country"
                        className='grow'
                        initialValue={props.address.country || 'USA'}
                    >
                        <Input placeholder='Country' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="zip"
                        className='grow'
                        initialValue=''
                    >
                        <Input placeholder='ZIP' />
                    </Form.Item>
                </div>
                <Button icon={<EditOutlined />}
                    htmlType="submit" type='primary'>{props.buttonText}</Button>
            </Form>
        </>
    )
}