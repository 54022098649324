// DeleteInventoryButton.tsx

import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import { HistoryOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { deleteInventoryItem } from "../../../../api/inventory/inventory";
import { useEditableContext, InventoryItemRecieveColumns } from '../EditableContext';
import {useNavigate} from "react-router-dom";

interface DeleteInventoryButtonProps {
    record: InventoryItemRecieveColumns;
}

const ViewHistoryButton: React.FC<DeleteInventoryButtonProps> = ({ record }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/inventory/history/${record.id}`);
    };


    return record.id < 0 ? (<></>) : (

        <Button onClick={handleClick} icon={<HistoryOutlined />} />
    );
};

export default ViewHistoryButton;
