import { axios } from '../axios';
import { AddressProps } from '../addresses/addresses';

export interface ClientProps {
    companyName: string,
    email: string,
    firstName: string,
    id: number,
    lastName: string,
    middleName: string,
    mobileNumber: string,
    notes: string,
    other: string,
    phoneNumber: string,
    suffix: string,
    title: string,
    addresses: [AddressProps]
    files: [FilesProps]
}

export interface FilesProps {
    id: number,
    name: string,
    link: string,
    createdAt: string,
    updatedAt: string
    customerID: number
}

export interface ClientAddProps {
    companyName?: string,
    email?: string,
    firstName: string,
    lastName: string,
    middleName?: string,
    mobileNumber?: string,
    phoneNumber?: string,
    suffix?: string,
    title?: string,
}




export const getAllClients = async () => {
    try {
        const response = await axios.get<ClientProps[]>('/api/clients/all');
        return response.data;
    } catch(error) {
        console.error(error);
    }
}

export const addNewClient = async (client: ClientAddProps) => {
    try {
        const response = await axios.post<ClientProps>('/api/clients/add', {client: client})
        return response;
    } catch(error: any) {
        console.error(error);
    }
}

export const updateClient = async (client: ClientAddProps, id: number) => {
    try {
        const response = await axios.post<ClientProps>(`/api/clients/${id}/update`, {client: client});
        return response;
    } catch(error) {
        console.error(error);
    }
}

export const getClientById = async (id: number) => {
    try {
        const response = await axios.get<ClientProps>(`/api/clients/${id}`);
        console.log(response.data);
        return response.data;
    } catch(error) {
        console.error(error);
    }
}

export const deleteClient = async (id: number) => {
    try {
        const response = await axios.delete(`/api/clients/${id}/delete`);
        return response;
    } catch(error) {
        console.error(error);
    }
}

export const uploadFile = async (file: File, id: number) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        // Adjust the URL as needed
        const response = await axios.post(`/api/clients/${id}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    } catch(error) {
        console.error(error);
        throw error; // Rethrow the error so the Dragger can catch it
    }
};

export const deleteFile = async (clientID: number, fileID: number) => {
    try {
        const response = await axios.delete(`/api/clients/${clientID}/deleteFile/${fileID}`);
        return response;
    } catch(error) {
        console.error(error);
    }
}

export const downloadFile = async (clientID: number, fileID: number, filename: string) => {
    try {
        const response = await axios.get(`/api/clients/${clientID}/download/${fileID}`, {
            responseType: 'blob'
        });

        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // Set the filename provided as parameter
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    } catch(error) {
        console.error(error);
    }
}

