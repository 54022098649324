import { customizeRequiredMark } from '../../components/RequiredMark';
import { ModalBase } from "../../components/Modal";
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Message } from '../../components/Message';
import { addNewClient, ClientAddProps, ClientProps } from '../../../api/clients/clients';
// import { AddClientSection } from './add_client'
// import { AddAddressSection } from './add_address';
import { AddressProps } from '../../../api/addresses/addresses';
import { useLocation } from 'react-router-dom';
import { dictionary } from '../../actions/Dictionary';
import { getClientById } from '../../../api/clients/clients';
import { AddInvoiceSection } from './add_invoice';

interface AddClientModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export {}

export function AddInvoiceModal(props: AddClientModalProps) {

    const [id, setId] = useState(-1);

    const location = useLocation();
    const [currentURL, setCurrentURL] = useState(location.pathname)

    useEffect(() => {
        if (location.pathname.includes(dictionary["viewInvoice"].link)) {
            setId(parseInt(location.pathname.split("/")[3]))
        } else {
            setId(-1)
        }
    }, [location]);  


    let tempAddress = {
        streetAddress1: "72960 Hunter Pine Suite 190",
        streetAddress2: "Suite 905",
        city: "Justinmouth",
        state: 'IL',
        zip: '',
        country: '',
        id: 0,
        latitude: 0,
        longitude: 0,
        customerId: 0
    }

    const [addresses, setAddresses] = useState<AddressProps[]>([]);
    
    function sendMessage(messageType: 'success' | 'error' | 'warning', messageText: string) {
        setMessageType(messageType)
        setMessageText(messageText)
        setMessageTrigger(true)
    }

    function resetMessage() {
        setMessageTrigger(false)
        setMessageType('warning')
        setMessageText('')
    }    

    const [messageTrigger, setMessageTrigger] = useState(false);
    const [messageType, setMessageType] = useState<'success' | 'error' | 'warning'>('warning');
    const [messageText, setMessageText] = useState('Something is wrong');

    return (
        <ModalBase open={props.open} setOpen={props.setOpen} title='' large>
            <Message type={messageType} text={messageText} trigger={messageTrigger}/>
            <div className='gap-4 py-4 flex flex-col'>
                 <section className='max-w-[800px] mx-auto grow'>
                    <AddInvoiceSection sendMessage={sendMessage} resetMessage={resetMessage} id={id} setId={setId} />
                </section>
                {/* {id !== -1 &&
                    <section className='max-w-[800px] mx-auto w-full grow'>
                        <AddAddressSection id={id} sendMessage={sendMessage} resetMessage={resetMessage}/>
                    </section>
                } */}
            </div>
        </ModalBase>
    )
}