import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { AddClientModal } from '../modals/client/ClientsDetails';
import { AddInvoiceModal } from '../modals/invoice/InvoicesDetails';
import { InventoryHistoryModal } from "../modals/inventory/InventoryHistory";
import {detirminePrviousPage, dictionary} from './Dictionary';

enum ModalType {
  None,
  AddClient,
  AddInvoice,
  ViewInventoryHistory,
}


function usePreviousPath() {
  const location = useLocation();
  const currentPathRef = useRef(location.pathname); // Initialize with the current path
  const prevPathRef = useRef(undefined); // Initialize as undefined for no previous location

  useEffect(() => {
    // The condition ensures the hook sets a previous path only after an initial render
    if (currentPathRef.current) {
      // Update the previous path ref with the old current path
      // @ts-ignore
      prevPathRef.current = currentPathRef.current;
    }
    // Then update the current path ref with the new path
    currentPathRef.current = location.pathname;
  }, [location.pathname]); // Only re-run this effect when the path changes

  // Return the previous path, which will be undefined for the first render
  return prevPathRef.current;
}

export function RootActions() {
  const location = useLocation();
  let navigate = useNavigate();
  const prevLocation = usePreviousPath();


  const [activeModal, setActiveModal] = useState(ModalType.None);

  const openModal = (modalType: ModalType) => {
    setActiveModal(modalType);
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === dictionary["addClient"].link || path.includes(dictionary["viewClient"].link)) {
      openModal(ModalType.AddClient);
    } else if (path.includes(dictionary["addInvoice"].link)) {
      openModal(ModalType.AddInvoice);
    } else if (path.includes(dictionary["viewInventoryItemHistory"].link)) {
      openModal(ModalType.ViewInventoryHistory);
    } else {
      openModal(ModalType.None);
    }
  }, [location.pathname]);

  function closeModal() {
    setActiveModal(ModalType.None);

    if (prevLocation) {

      navigate(prevLocation);

    } else {

      let prev = detirminePrviousPage(location.pathname);
      if (location.pathname[location.pathname.length - 1].match(/\d/)) {
        prev = detirminePrviousPage(location.pathname.substring(0, location.pathname.lastIndexOf("/")));
      }
      navigate(prev);

    }
  }

  return (
      <>
        {activeModal === ModalType.AddClient && <AddClientModal open={true} setOpen={() => closeModal()} />}
        {activeModal === ModalType.AddInvoice && <AddInvoiceModal open={true} setOpen={() => closeModal()} />}
        {activeModal === ModalType.ViewInventoryHistory && <InventoryHistoryModal open={true} setOpen={() => closeModal()} />}
      </>
  );
}
