// DeleteInventoryButton.tsx

import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import { RotateLeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {recoverInventoryItem } from "../../../../api/inventory/inventory";
import { useEditableContext, InventoryItemRecieveColumns } from '../EditableContext';

interface DeleteInventoryButtonProps {
    record: InventoryItemRecieveColumns;
    trigger: boolean;
    setTrigger: (trigger: boolean) => void;
}

const RecoverInventoryButton: React.FC<DeleteInventoryButtonProps> = ({ record , trigger, setTrigger}) => {
    const { data, setData } = useEditableContext();

    const recoverItem = () => {
        recoverInventoryItem(record.id).then(() => {
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);
            newData[index].deleted = false;
            setData(newData);
            message.success("Item recovered");
            setTrigger(!trigger);
        }).catch(() => {
            message.error("Error recovering item");
        });
    };

    return record.id < 0 ? (<></>) : (
        <Popconfirm
            title="Recover this item?"
            onConfirm={recoverItem}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
            <Button icon={<RotateLeftOutlined />} >Recover Item</Button>
        </Popconfirm>
    );
};

export default RecoverInventoryButton;
