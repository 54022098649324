import { Select } from 'antd';
import { dictionary, findTitleBsedOnLink } from '../actions/Dictionary';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export function SearchBar() {

    const [value, setValue] = useState<string>('')
    const location = useLocation();
    const [currentURL, setCurrentURL] = useState(location.pathname)

    useEffect(() => {
        const link = findTitleBsedOnLink(currentURL)
        setValue(link)

    }, [currentURL]);

    useEffect(() => {
        setCurrentURL(location.pathname)
    }, [location]);

    function PrepareOpions(): { value: string, label: string }[] {
        return Object.keys(dictionary)
            .filter((key) => dictionary[key].title !== "")
            .map((key) => {
                return {
                    value: dictionary[key].link,
                    label: dictionary[key].title
                }
            })
    }

    const navigate = useNavigate();

    const onChange = (link: string) => {
        navigate(link)
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div className="flex-col flex max-h-[90px] items-start justify-start pr-4 py-4 gap-2.5 bg-white">
            <Select
                className='w-full'
                size='large'
                showSearch
                placeholder="Search..."
                optionFilterProp="children"
                onChange={onChange}
                filterOption={filterOption}
                options={PrepareOpions()}
                onDeselect={() => setValue('')}
                allowClear
                autoClearSearchValue
                value={value}
            />
        </div>
    )
}