import React, { useEffect } from 'react';
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
import { CustomMarker } from './Marker';
import { CustomMapControl } from './MapControl';
import { getAddresses } from '../../../api/addresses/addresses';
import { MapAddressProps } from '../../../api/addresses/addresses';
import { useState } from 'react';


interface GoogleMapSubpageProps{
    center: {
      lat: number;
      lng: number;
    };
    zoom: number;
}

export function GoogleMapSubpage(props: GoogleMapSubpageProps){

    const [clients, setClients] = useState<MapAddressProps[]>([])
    const [originalClients, setOriginalClients] = useState<MapAddressProps[]>([])
    const [filter, setFilter] = useState('')

    function filterAddresses(){
      if(filter && filter != ''){
        return originalClients.filter((client) => {
          return client.customerName.toLowerCase().includes(filter.toLowerCase())
        })
      }
      return originalClients
    }


    useEffect(() => {
      getAddresses().then((data) => {
        console.log(data)
        if(data){
          setClients(data)
          setOriginalClients(data)
        }
      })
    }, [])
    
    useEffect(() => {
      if(filter){
        setClients(filterAddresses())
      }
    }, [filter])

    return (<div className='h-full w-full'>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY || "undefined"}>
            <Map center={props.center} zoom={props.zoom} mapId={process.env.REACT_APP_GOOGLE_MAP_ID || "undefined"}>
                <CustomMapControl onFilter={setFilter}/>
                {clients.map((client, index) => {
                  if (client.position.lat && client.position.lng)
                    return (<CustomMarker key={index} {...client}/>)
                }
                )}
            </Map>
          </APIProvider>
      </div>)
  }


// export const GoogleMapSubpage = ({ children, ...props }: GoogleMapSubpageProps) => (
  
//   <div className='h-full w-full'>
//     <GoogleMapReact
//       bootstrapURLKeys={{
//         key: process.env.REACT_APP_MAP_KEY || '',
//       }}
//       {...props}
//     >
//       {children}
//     </GoogleMapReact>
//   </div>
// );
