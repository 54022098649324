import { Button, Checkbox, Form, Input } from 'antd';
import { customizeRequiredMark } from '../../components/RequiredMark';
import { Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { addNewClient, ClientAddProps, updateClient } from '../../../api/clients/clients';
import { getClientById } from '../../../api/clients/clients';

interface AddClientModalProps {
    sendMessage: (messageType: 'success' | 'error' | 'warning', messageText: string) => void;
    resetMessage: () => void;
    setId: (id: number) => void;
    id: number;
}


export function AddClientSection(props: AddClientModalProps) {

    const { Title } = Typography;
    
    const [form] = Form.useForm();

    const client = {
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        companyName: '',
        email: '',
        phoneNumber: '',
        mobileNumber: '',
    };

    useEffect(() => {
        if (props.id !== -1) {

            form.setFieldsValue({
                title: 'Loading...',
                firstName: 'Loading...',
                middleName: 'Loading...',
                lastName: 'Loading...',
                suffix: 'Loading...',
                companyName: 'Loading...',
                email: 'Loading...',
                phoneNumber: 'Loading...',
                mobileNumber: 'Loading...',
            });

            getClientById(props.id).then((response) => {
                form.setFieldsValue({
                    title: response?.title,
                    firstName: response?.firstName || "ERROR",
                    middleName: response?.middleName,
                    lastName: response?.lastName || "ERROR",
                    suffix: response?.suffix,
                    companyName: response?.companyName,
                    email: response?.email,
                    phoneNumber: response?.phoneNumber,
                    mobileNumber: response?.mobileNumber,
                });
            })
        } else {
            form.setFieldsValue({
                title: '',
                firstName: '',
                middleName: '',
                lastName: '',
                suffix: '',
                companyName: '',
                email: '',
                phoneNumber: '',
                mobileNumber: '',
            });
        }
    }, [props.id]);

    const onFinish = async (values: ClientAddProps) => {
        if (props.id === -1) {
            await createNewClient(values)
        } else {   
            await updateClientDetails(values, props.id)
        }
    };

    async function createNewClient(values: ClientAddProps) {
        await addNewClient(values).then((response) => {
            if (response?.status === 200) {
                props.sendMessage('success', 'Client added successfully')
                console.log(response.data)
                props.setId(response.data.id || -1)
            } else {    
                props.sendMessage('error', 'Client could not be added')
            }
        }).then(() => {
            setTimeout(() => {
                props.resetMessage()
            }, 3000);
        })
    }

    async function updateClientDetails(values: ClientAddProps, id: number) {
        await updateClient(values, id).then((response) => {
            if (response?.status === 200) {
                props.sendMessage('success', 'Changes are saved')
                console.log(response.data)
                props.setId(response.data.id || -1)
            } else {    
                props.sendMessage('error', 'Client could not be modified')

            }
        }).then(() => {
            setTimeout(() => {
                props.resetMessage()
            }, 3000);
        })
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    type FieldType = ClientAddProps;

    return (
        <>
            <Title level={2}>Client's Details</Title>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                requiredMark={customizeRequiredMark}
                form={form}
                initialValues={client}
            >
                <div className='flex flex-row gap-4'>
                    <Form.Item<FieldType>
                        name="title"
                        className='w-[60px]'
                    
                    >
                        <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="firstName"
                        rules={[{ required: true, message: 'Please provide first name' }]}
                        className='grow'
                    >
                        <Input placeholder='First Name' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="middleName"
                        className='grow'
                    >
                        <Input placeholder='Middle Name' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="lastName"
                        rules={[{ required: true, message: 'Please provide last name' }]}
                        className='grow'
                    >
                        <Input placeholder='Last Name' />
                    </Form.Item>
                    <Form.Item<FieldType>
                        name="suffix"
                        className='w-[60px]'
                    >
                        <Input placeholder='Suffix' />
                    </Form.Item>
                </div>
                <Form.Item<FieldType>
                    name="companyName"
                    className='grow'
                >
                    <Input placeholder='Company Name' />
                </Form.Item>
                <Form.Item<FieldType>
                    name="email"
                    className='grow'
                >
                    <Input placeholder='Email Address' />
                </Form.Item>
                <Form.Item<FieldType>
                    name="mobileNumber"
                    className='grow'
                >
                    <Input placeholder='Mobile Nuber' />
                </Form.Item>
                <Form.Item<FieldType>
                    name="phoneNumber"
                    className='grow'
                >
                    <Input placeholder='Phone Number (home)' />
                </Form.Item>
                <Button htmlType="submit" type='primary'> {props.id === -1 && <>Add New Client</>} {props.id !== -1 && <>Submit Edits</>} </Button>
            </Form>
        </>
    )
}