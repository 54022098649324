import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ClientProps } from '../../../api/clients/clients';
import { AddressProps, addressToString } from '../../../api/addresses/addresses';
import { EditOutlined, FileOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { dictionary } from '../../actions/Dictionary';
import { deleteClient } from '../../../api/clients/clients';

// companyName: string,
// email: string,
// firstName: string,
// id: number,
// lastName: string,
// middleName: string,
// mobileNumber: string,
// notes: string,

interface DataType {
  key: React.Key;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  mobileNumber: string;
  address: string;
  id: number;
}


const columns: ColumnsType<DataType> = [
  {
    title: 'First Name',
    width: 100,
    dataIndex: 'firstName',
    key: '1',
    fixed: 'left',
    render: (text: string) => <p className='font-bold'>{text}</p>,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    width: 100,
    key: '2',
    fixed: 'left',

    render: (text: string) => <p className='font-bold'>{text}</p>,

  },
  {
    title: 'Email Address',
    dataIndex: 'email',
    width: 100,
    key: '4',
    render: (email: string) => <a href={`mailto:${email}`} className='text-blue-ortega'>{email}</a>
  },
  {
    title: 'Mobile Number',
    dataIndex: 'mobileNumber',

    key: '5',
    render: (phone: string) => <a href={`tel:${phone}`} className='text-blue-ortega'>{phone}</a>
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: '6',
  },
  {
    title: "",
    dataIndex: 'id',
    key: '7',
    fixed: 'right',
    width: 180,
    render: () => <Button icon={<FileOutlined />}>Invoices / Quotes</Button>
  },
  {
    title: "",
    dataIndex: 'id',
    key: '8',
    fixed: 'right',
    width: 150,
    render: (id) => <EditClientButton link={dictionary["viewClient"].link + id} />
  },
  {
    title: "",
    dataIndex: 'id',
    key: '7',
    fixed: 'right',
    render: (id) => <DeleteClientButton id={id} />
  },
];


function DeleteClientButton(props: { id: string }) {
  const navigate = useNavigate();

  async function handleClick() {
    console.log(props.id)
    await deleteClient(parseInt(props.id))
  };


  return (
    <Popconfirm
      onConfirm={handleClick}
      title={"Delete the client"}
      description="Are you sure to delete this client?" placement='topLeft'
      icon={<QuestionCircleOutlined style={{ color: 'red' }}

      />}>

      <Button danger icon={<DeleteOutlined />}>{props.id}</Button>
    </Popconfirm>
  );
}


function EditClientButton(props: { link: string }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(props.link);
  };

  return (
    <Button type='primary' className='bg-blue-ortega' icon={<EditOutlined />} onClick={handleClick}>
      Edit Client
    </Button>
  );
}


interface ClientsTableProps {
  clients: ClientProps[]
}

export function ClientsTable(props: ClientsTableProps) {
  const [fixedTop, setFixedTop] = useState(false);

  const [data, setData] = useState<DataType[]>([]);


  function updateClientsData() {

    let tempData: DataType[] = [];

    for (let i = 0; i < props.clients.length; i++) {
      tempData.push({
        key: i,
        firstName: props.clients[i].firstName ? props.clients[i].firstName : "",
        lastName: props.clients[i].lastName ? props.clients[i].lastName : "",
        companyName: props.clients[i].companyName ? props.clients[i].companyName : "",
        email: props.clients[i].email ? props.clients[i].email : "",
        mobileNumber: props.clients[i].mobileNumber ? props.clients[i].mobileNumber : "",
        address: props.clients[i].addresses[0] ? addressToString(props.clients[i].addresses[0]) : "",
        id: props.clients[i].id ? props.clients[i].id : -1,
      });
    }

    setData(tempData)

  }


  useEffect(() => {
    updateClientsData()
  }, [props.clients]);



  return (
    <Table
      columns={columns}
      loading={props.clients.length === 0}
      dataSource={data}
      className='px-4'
      size='large'
      scroll={{ x: true }}
      summary={() => (
        <Table.Summary fixed={fixedTop ? 'top' : 'bottom'}>

        </Table.Summary>
      )}

    />
  );
};

