import React, { useEffect, useState, useCallback } from 'react';
import {Button, Checkbox, Input} from 'antd';
import { PlusCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import {getExcelReport, getInventoryItems, InventoryItemRecieve} from "../../../api/inventory/inventory";
import { InventoryTable } from './InventoryTable';
import { EditableContext } from './EditableContext';
import {Loading} from "../../components/Loading";
import { nanoid } from 'nanoid';
import {InventoryReportGenerator} from "./InventoryReportGenerator";


interface InventoryItemRecieveColumns extends InventoryItemRecieve {
    inEdit: boolean;
    key: string;
}


export function InventorySubpage() {
    const location = useLocation();

    const [includeDeleted, setIncludeDeleted] = useState(false);
    const [showOnlyDeleted, setShowOnlyDeleted] = useState(false);

    const [data, setData] = useState<InventoryItemRecieveColumns[]>([]);
    const [allInventoryItems, setAllInventoryItems] = useState<InventoryItemRecieveColumns[]>([]);
    const [searchKey, setSearchKey] = useState("");
    const [newIdNumber, setNewIdNumber] = useState(-1);
    const [trigger, setTrigger] = useState(false);
    const [loadingState, setLoadingState] = useState<"loading" | "success" | "error">("loading");
    const [openGenerator, setOpenGenerator] = useState(false);

    const getInventory = useCallback(async (newItem: boolean) => {
        try {
            const data = await getInventoryItems();

            const formattedData = data.map((item) => ({
                ...item,
                inEdit: false,
                key: nanoid()
            }));

            if (newItem) {
                formattedData.unshift({
                    id: newIdNumber,
                    name: "",
                    description: "",
                    price: 0,
                    quantity: 0,
                    linkToPicture: "",
                    inEdit: true,
                    key: nanoid(),
                    deleted: false,
                    createdAt: "",
                    updatedAt: ""
                });
                setNewIdNumber(prevId => prevId - 1);
            }

            setAllInventoryItems(formattedData);
            setData(formattedData);
            setLoadingState("success");


        } catch (e) {
        setLoadingState("error");
        }
    }, []);

    useEffect(() => {
        if (location.pathname === "/inventory/new") {
            getInventory(true);
        } else {
            getInventory(false);
        }
    }, [getInventory, location.pathname]);

    const findElements = useCallback((key: string) => {
        return key === "" ? allInventoryItems : allInventoryItems.filter((item) =>
            item.id.toString().toLowerCase().includes(key.toLowerCase()) ||
            item.name.toLowerCase().includes(key.toLowerCase()) ||
            item.description.toLowerCase().includes(key.toLowerCase()) ||
            item.price.toString().toLowerCase().includes(key.toLowerCase()) ||
            item.linkToPicture.toLowerCase().includes(key.toLowerCase()) ||
            item.id < 0 ||
            item.inEdit
        );
    }, [allInventoryItems]);

    useEffect(() => {
        let elements = findElements(searchKey);
        if (!includeDeleted) {
            elements = elements.filter((item) => !item.deleted);
        } else if (showOnlyDeleted) {
            elements = elements.filter((item) => item.deleted);
        }
        setData(elements);
    }, [searchKey, findElements, includeDeleted, showOnlyDeleted, trigger]);

    const addNewRow = useCallback(async () => {
        setSearchKey("");
        const newData: InventoryItemRecieveColumns = {
            id: newIdNumber,
            name: "",
            description: "",
            price: 0,
            quantity: 0,
            linkToPicture: "",
            inEdit: true,
            key: nanoid(),
            deleted: false,
            createdAt: "",
            updatedAt: ""
        };
        setNewIdNumber(prevId => prevId - 1);
        await getInventory(false);
        setData([newData, ...data]);
        setAllInventoryItems([newData, ...data]);
    }, [newIdNumber, data, allInventoryItems]);

    function toggleIncludeDeleted() {
        if (!showOnlyDeleted) {
            setIncludeDeleted(!includeDeleted);
        }
    }

    function toggleShowOnlyDeleted() {
        setIncludeDeleted(!showOnlyDeleted);
        setShowOnlyDeleted(!showOnlyDeleted);

    }

    return (
        <div className="w-full h-full flex flex-col gap-2">
            <div className='flex flex-row gap-2 px-4 pt-4 items-center'>
                <Input
                    placeholder="Search..."
                    value={searchKey}
                    className={'w-[300px]'}
                    onChange={(e) => setSearchKey(e.target.value)}
                />
                <Button
                    type='primary'
                    icon={<PlusCircleOutlined />}
                    onClick={addNewRow}
                >
                    Add New Item
                </Button>
                <Checkbox onChange={toggleIncludeDeleted} checked={includeDeleted} className={'flex flex-row items-center'}><p>Include deleted</p></Checkbox>
                <Checkbox onChange={toggleShowOnlyDeleted} checked={showOnlyDeleted} className={'flex flex-row items-center'}>Show only deleted</Checkbox>
                <Button
                    type="primary"
                    icon={<FileExcelOutlined />}
                    onClick={() => setOpenGenerator(!openGenerator)}
                >
                    Generate Report
                </Button>
                <Loading state={loadingState} errorMessage={"Error while fetching the data."}/>
            </div>
            <div className={"flex flex-col gap-2 px-4"}>
                <EditableContext.Provider value={{ data, setData }}>
                    <InventoryTable trigger={trigger} setTrigger={setTrigger} reloadInventory={getInventory}/>
                </EditableContext.Provider>
            </div>
            <InventoryReportGenerator open={openGenerator} setOpen={setOpenGenerator} />
        </div>
    );
}
