import { InvoicesTable } from './InvoicesTable'
import {getAllInvoices, InvoiceDataRecieved} from '../../../api/documents/invoices'
import { useEffect, useState } from 'react'
import { Button, Input } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { dictionary } from "../../actions/Dictionary"
import { addressToString, AddressProps} from '../../../api/addresses/addresses'
import { useLocation } from 'react-router-dom'

export function InvoicesSubpage() {
    const navigate = useNavigate()

    const [allInvoices, setAllInvoices] = useState<InvoiceDataRecieved[]>([])
    const [invoices, setInvoices] = useState<InvoiceDataRecieved[]>([])
    const [searchKey, setSearchKey] = useState("");
    const location = useLocation();

    
    async function getInvoices() {
        const result = await getAllInvoices()
        console.log(result)
        if (result.status === 200) {
            setInvoices(result.data)
            setAllInvoices(result.data)
            console.log(result.data)
        } else {
            console.log(result)
        }
    }

    // a function that finds elements in the clients array that match the search key, handling the search key being empty
    function findElements(key: string){
        if(key === ""){
            return allInvoices
        }
        else{
            return allInvoices.filter((invoice) => {
                return invoice.id.toString().toLowerCase().includes(key.toLowerCase()) ||
                    invoice.customer.firstName?.toLowerCase().includes(key.toLowerCase()) ||
                    invoice.customer.lastName?.toLowerCase().includes(key.toLowerCase()) ||
                    invoice.customer.companyName?.toLowerCase().includes(key.toLowerCase()) ||
                    invoice.customer.email?.toLowerCase().includes(key.toLowerCase()) ||
                    invoice.customer.mobileNumber?.toLowerCase().includes(key.toLowerCase()) ||
                    invoice.services?.some((service) => service.name.toLowerCase().includes(key.toLowerCase())) ||
                    invoice.tags.toLowerCase().includes(key.toLowerCase())
            })
        }
    }

    useEffect(() => {
        getInvoices()
    }, [location.pathname])

    useEffect(() => {
        setInvoices(findElements(searchKey))
    },[searchKey] )

    return (
        <div className="w-full h-full flex flex-col gap-2">
            <div className='w-[400px] flex flex-row gap-2 px-4 pt-4'>
                <Input placeholder="Search..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                <Button type='primary' className='bg-blue-ortega' icon={<PlusCircleOutlined />} onClick={() => navigate(dictionary["addInvoice"].link)}>Add New Invoice</Button>
            </div>
            <InvoicesTable invoices={invoices}/>
        </div>  
    )
}