import {axios} from '../axios';
import {AxiosResponse} from "axios";

export interface InvoiceService {
    name: string;
    rate: number;
    quantity: number;
    amount: number;
    saveAsTemplate: boolean;
    tax: boolean;
}

interface Customer {
    companyName: string;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    middleName?: string;
    mobileNumber: string;
    notes?: string;
    other?: any;
    phoneNumber?: string;
    suffix?: string;
    title?: string;
}


export interface InvoiceData {
    customerId: number;
    invoiceDate: Date
    dueDate: Date
    tags: string;
    total: number;
    balance: number;
    PaymentForm: {
        name: string;
        amount: number;
    }
    services: InvoiceService[]
}

export interface InvoiceDataRecieved extends InvoiceData {
    id: number;
    customer: Customer;
}
export function createInvoice(invoice: InvoiceData) {
    try {
        return axios.post('/api/invoices/add', invoice);
    }
    catch (error) {
        console.log(error);
    }
}

export function getAllInvoices(): Promise<AxiosResponse<InvoiceDataRecieved[]>> {
    return axios.get('/api/invoices/all');
}