import  { Outlet } from 'react-router-dom'
import { MainMenu } from './components/Menu';
import { RootActions } from './actions/Root';
import { SearchBar } from "./components/SearchBar";

export function DashboardPage() {

    return (
        <div className="w-full p-0 m-0 flex flex-row bg-grey">
            <div className="hidden phone:blocktext-white w-[300px] text-center">
                We do not support mobile devices yet. Please use a desktop or laptop computer.
            </div>
            <div className="w-screen h-screen p-0 m-0 flex flex-row bg-blue-ortega-bg phone:hidden">
                <RootActions/> 
                <div className="min-w-[280px] max-w-[280px] flex flex-col w-full">
                    <a href="/">
                        <div className="flex h-[72px] items-center justify-center gap-2.5 bg-white">
                            <img src="assets/logo.svg" width="150" className="m-auto" alt="Company's logo" />
                        </div>
                    </a>
                    <MainMenu/>
                </div>
                <div className="flex flex-col grow">
                    <SearchBar />
                    <MainContainer>
                    </MainContainer>
                </div>
            </div>
        </div>
    )
}


interface MainContainerProps {
    children: React.ReactNode;
}

function MainContainer(props: MainContainerProps) {

    return (
        <div className="flex-col grow items-center justify-center p-0 gap-0 pr-4 pb-4 bg-white">
            <div className='grow h-full bg-grey-blue border-grey-ant rounded-xl !overflow-scroll'>
                <Outlet/>
            </div>
        </div>
    )
}
