import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { detirminePrviousPage} from '../actions/Dictionary';


interface ModalProps {
    children?: React.ReactNode;
    open: boolean;
    large?: boolean;
    title?: string;
    setOpen: (open: boolean) => void;
}


export function ModalBase(props: ModalProps) {

  const location = useLocation();

  const [prevPath, setPrevPath] = useState('');

  useEffect(() => {
    setPrevPath(detirminePrviousPage(location.pathname));
  }, [location]);

  const navigate = useNavigate();
  let enabled = false;

  function navigateUpOneLevel() {
    navigate(prevPath);
  }


  useEffect(() => {
    if (props.open === true){
      enabled = true;
    }
    if(props.open === false){
      navigateUpOneLevel()
      enabled = false;
    }
  }, [props.open])

  return (
    <>
      <Modal
        title={props.title}
        centered
        open={props.open}
        onOk={() => props.setOpen(false)}
        onCancel={() => props.setOpen(false)}
        width={`${props.large ? "fit-content" : '400px'}`}
      >
        <div className={`${props.large ? "fit-content" : ''}`}>
        {props.children}
        </div>
      </Modal>
    </>
  );
};