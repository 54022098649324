import Axios from 'axios';
import { logout } from './auth/passport';

export let axios = Axios.create();
axios.defaults.baseURL = 'https://finback.aortegahvac.com/';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    response => {
        //2xx...
        return response;
    },
    function (error) {
        //4xx...
        if (error.response.status === 401){
            logout();   
        }
        return Promise.reject(error);
    }
);

export function downloadBlobAsFile(blob: Blob, fileName: string) {
    // Create a temporary link to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up the temporary URL and link element
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
}
