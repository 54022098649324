// DeleteInventoryButton.tsx

import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { deleteInventoryItem } from "../../../../api/inventory/inventory";
import { useEditableContext, InventoryItemRecieveColumns } from '../EditableContext';

interface DeleteInventoryButtonProps {
    record: InventoryItemRecieveColumns;
    trigger: boolean;
    setTrigger: (trigger: boolean) => void;
}

const DeleteInventoryButton: React.FC<DeleteInventoryButtonProps> = ({ record , trigger, setTrigger}) => {
    const { data, setData } = useEditableContext();

    const deleteItem = () => {
        deleteInventoryItem(record.id).then(() => {
            const newData = [...data];
            const index = newData.findIndex((item) => record.key === item.key);
            newData[index].deleted = true;
            setData(newData);
            message.success("Item deleted");
            setTrigger(!trigger);
        }).catch(() => {
            message.error("Error deleting item");
        });
    };

    return record.id < 0 ? (<></>) : (
        <Popconfirm
            title="Delete this item?"
            onConfirm={deleteItem}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
            <Button danger icon={<DeleteOutlined />} />
        </Popconfirm>
    );
};

export default DeleteInventoryButton;
