// EditableContext.tsx

import React, { createContext, useContext } from 'react';
import { InventoryItemRecieve } from "../../../api/inventory/inventory";

export interface InventoryItemRecieveColumns extends InventoryItemRecieve {
    inEdit: boolean;
    imageBlob?: File;
    key: string;
}

interface EditableContextValue {
    data: InventoryItemRecieveColumns[];
    setData: React.Dispatch<React.SetStateAction<InventoryItemRecieveColumns[]>>;
}

export const EditableContext = createContext<EditableContextValue | null>(null);

export const useEditableContext = (): EditableContextValue => {
    const context = useContext(EditableContext);
    if (!context) {
        throw new Error('useEditableContext must be used within an EditableContextProvider');
    }
    return context;
};
